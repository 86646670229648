import React from "react"
import Layout from "../components/layout"
import PageHeader from "../components/common/PageHeader"
import AsideColumnBox from "../components/common/AsideColumnBox"

import { useStaticQuery, graphql } from "gatsby"
import MainPageFeaturedImage from "../components/common/MainPageFeaturedImage"
import IngressPage from "../components/common/IngressPage"
import Seo from "../components/seo"

export default function RapporterPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 339 }) {
        title
        language {
          slug
        }
        translations {
          uri
        }
        rapporter {
          ingress
          filer {
            titel
            fil {
              localFile {
                publicURL
              }
            }
          }
          gulBoxRapporter {
            text
            knappar {
              lank
              text
            }
          }
        }
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const { title, rapporter, featuredImage, content } = data.wpPage
  const boxes = rapporter.gulBoxRapporter
  const filer = rapporter.filer
  const translation = data.wpPage.translations[0]?.uri
  const lang = data.wpPage.language.slug

  return (
    <Layout lang={lang} translation={translation}>
      <Seo title={data.wpPage.title} />
      <div className="space-y-8">
        <PageHeader text={title} />
        <IngressPage text={rapporter.ingress} />

        <MainPageFeaturedImage img={featuredImage} />
        <div
          id="wp-page"
          className="md:grid grid-cols-2 gap-8 space-y-4 lg:space-y-0"
        >
          <div className="space-y-4 -mt-2 left pb-4">
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              className="space-y-4"
            />
            <div className="space-y-2">
              <div className="font-bold">Ladda ner exempelrapporter</div>
              <div className="space-y-2">
                {filer.map((post, i) => {
                  return <File key={i} post={post} />
                })}
              </div>
            </div>
          </div>
          <div>
            {boxes?.map((box, i) => {
              return <AsideColumnBox key={i} box={box} />
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

function File({ post }) {
  return (
    <div className="text-xs uppercase ">
      <span className="border-b-2 border-primary leading-normal pb-1">
        <a href={post.fil?.localFile.publicURL}>{post.titel}</a>
      </span>
    </div>
  )
}
